import React from 'react';
import { Spin, Row, Col, Input, Select, Pagination } from 'antd';
import MovieItemComponent from './MovieItemComponent';
import TVItemComponent from './TVItemComponent';

const { Search } = Input;
const { Option } = Select;

export default class SearchComponent extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      movies: null,
      isLoading: false,
      searchType: 'movie',
      searchVal: '',
      pageSize: 20,
      pageIndex: 1,
      totalPages: 0,
      totalResults: 0,
    }
  }

  refreshData = (type, value, page) => {
    this.setState({ isLoading: true, movies: null });
    fetch(`https://api.themoviedb.org/3/search/${type}?api_key=04b256f451c0e618b5735841206fdedc&query=${value}&page=${page}`)
      .then(response => {
        return response.json();
      })
      .then(data => {
        this.setState({
          isLoading: false,
          movies: data.results,
          totalPages: data.total_pages,
          totalResults: data.total_results,
          pageIndex: data.page,
        });
      });
  }

  pageChange = (page) => {
    this.setState({ pageIndex: page });
    this.refreshData(this.state.searchType, this.state.searchVal, page);
  }

  handleSearch = (value) => {
    if (value) {
      this.refreshData(this.state.searchType, value, 1);
    }
  }

  handleCategoryChange = (value) => {
    this.setState({ searchType: value, movies: null, searchVal: '', totalPages: 0, totalResults: 0, pageIndex: 1 });
  }

  handleSearchOnChange = (e) => {
    this.setState({ searchVal: e.target.value });
  }

  getRow = () => {
    let result = [];
    let rows = [];
    if (this.state.movies) {
      let rowObj = [];
      for (let i = 0; i < this.state.movies.length; i++) {
        if (rowObj.length === 4) {
          result.push(rowObj);
          rowObj = [];
        }

        /* 电影和电视剧 2种情况 */
        if (this.state.searchType === 'movie') {
          rowObj.push(<MovieItemComponent key={this.state.movies[i].id} {...this.state.movies[i]} />);
        }
        else {
          rowObj.push(<TVItemComponent key={this.state.movies[i].id} {...this.state.movies[i]} />);
        }
      }

      result.push(rowObj);


      for (let i = 0; i < result.length; i++) {
        rows.push(
          <Row justify="center" key={i}>
            {result[i]}
          </Row>
        );
      }

      return rows;
    }
    else {
      return null;
    }
  }

  render() {
    return (
      <div className="Movies">
        <Spin spinning={this.state.isLoading}>
          <Row justify="center">
            <Col sm={20} md={21} className="search">
              <Select defaultValue="movie" style={{ width: 110, marginRight: 5 }} onChange={this.handleCategoryChange}>
                <Option value="movie">Movie</Option>
                <Option value="tv">TV Show</Option>
              </Select>
              <Search
                placeholder="Search"
                onSearch={this.handleSearch}
                style={{ width: 180, marginTop: 3, marginBottom: 3 }}
                value={this.state.searchVal}
                onChange={this.handleSearchOnChange}
              />
            </Col>
          </Row>
          <Row justify="center">
            <Col sm={22} md={21} className="search" >
              {
                this.state.movies ?
                  <Pagination
                    current={this.state.pageIndex}
                    total={this.state.totalResults}
                    defaultPageSize={20}
                    showSizeChanger={false}
                    onChange={this.pageChange}
                  /> : null
              }
            </Col>
          </Row>
          {
            this.getRow()
          }
        </Spin>
      </div>
    );
  }
}
