import React from 'react';
import './App.css';
import { Layout, Menu, Row, Col } from 'antd';
import homeComponent from './components/HomeComponent';
import MoviesListComponent from './components/MoviesListComponent';
import SearchComponent from './components/SearchComponent';
import { BrowserRouter as Router, Switch, Route, Link } from "react-router-dom";
import logo from './img/logo.png';

const { Header, Content } = Layout;

export default class App extends React.Component {
  render() {
    return (
      <Router>
        <Layout className="App">
          <Header style={{margin:'0', padding:'0'}}>
            <Row justify={"center"}>
              <Col sm={20} xs={20} lg={13}>
              <img style={{height:50,float: "left", marginTop:6, marginRight: 5}} src={logo} alt=""></img>
              {/* 解决刷新的问题 */}
                <Menu theme="dark" mode="horizontal" defaultSelectedKeys={[(window.location.pathname.split('/')[1])?window.location.pathname.split('/')[1]:'home']}>
                  <Menu.Item key="home"><Link to="/">Home</Link></Menu.Item>
                  <Menu.Item key="movies"><Link to="/movies">Movies</Link></Menu.Item>
                  <Menu.Item key="search"><Link to="/search">Search</Link></Menu.Item>
                </Menu>
              </Col>
            </Row>

          </Header>
          <Layout>
            <Layout>
              <Content>
                <Switch>
                  <Route exact path="/" component={homeComponent}></Route>
                  <Route exact path="/movies" component={MoviesListComponent}></Route>
                  <Route exact path="/search" component={SearchComponent}></Route>
                </Switch>
              </Content>
            </Layout>
          </Layout>
        </Layout>
      </Router>
    );
  }
}

