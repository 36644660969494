import React from 'react';
import '../App.css';
import { Col } from 'antd';

export default class TVItemComponent extends React.Component {

  render() {
    return (
      <Col xxl={5} xl={5} lg={10} md={10} sm={20} xs={20}  className='MovieItem' >
        {
          this.props.poster_path ? 
          <img src={`https://image.tmdb.org/t/p/w500${this.props.poster_path}`} alt='' />
          :null
        }
        <div>
        <h5>Name: {this.props.name}</h5>
        <h5>First Air Date: {this.props.first_air_date}</h5>
        <h5>Vote Average: {this.props.vote_average}</h5>
        </div>
      </Col>
      );
  }
}
