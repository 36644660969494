import React from 'react';
import { Row, Col, Carousel, Spin, List } from 'antd';
import CarouselItemComponent from './CarouselItemComponent';
import SearchItemComponent from './SearchItemComponent';

export default class HomeComponent extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      tv: [],
      isLoading: false,
    }
  } 

  /* 走马灯控件 */
  getMoives = () => {
    if (this.state.tv) {
      return <Carousel
        autoplay
        dots={{ className: 'CarouselDot' }}
      >
        {/* 使用子控件 */}
        {this.state.tv.map(x => {
          return <CarouselItemComponent
            key={x.id}
            src={`https://image.tmdb.org/t/p/original${x.backdrop_path}`}
            title={x.title}
          />
        })}
      </Carousel>
    }
    else {
      return null;
    }
  }


  /* 页面加载后调用API */
  componentDidMount() {

    this.setState({ isLoading: true });

    fetch(`https://api.themoviedb.org/3/movie/now_playing?api_key=04b256f451c0e618b5735841206fdedc&page=1`)
      .then(response => {
        return response.json();
      })
      .then(data => {
        this.setState({
          isLoading: false,
          tv: data.results,
        });
      });
  }

  render() {
    return (
      <div className='Home'>
        <Spin spinning={this.state.isLoading}>
          <Row justify={"center"} >
            <Col sm={24} xs={24} lg={13}>
              {
                this.getMoives()
              }
            </Col>
          </Row>

          <Row justify={"center"}>
            <Col sm={24} xs={24} lg={13} className='NowPlaying' >
              <div>Now Playing List</div>
              {/* 使用List控件 */}
              <List
                itemLayout="vertical"
                size="large"
                dataSource={this.state.tv}
                renderItem={item => (
                  <List.Item
                    key={item.id}
                  >
                    <SearchItemComponent {...item} />

                  </List.Item>
                )}
              />
            </Col>
          </Row>
        </Spin>
      </div>
    );
  }
}
