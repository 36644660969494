import React from 'react';
import { Spin, Row, Col, Pagination, Radio } from 'antd';
import MovieItemComponent from './MovieItemComponent';

export default class MovieListComponent extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      movies: null,
      movieType: 'now_playing',
      pageSize: 20,
      pageIndex: 1,
      totalPages: 0,
      totalResults: 0,
      isLoading: false,
    }
  }

  /* 根据类型，页数，调用API， 取得数据 */
  refreshData = (type, page) => {
    this.setState({ isLoading: true, movies: null });
    fetch(`https://api.themoviedb.org/3/movie/${type}?api_key=04b256f451c0e618b5735841206fdedc&page=${page}`)
      .then(response => {
        return response.json();
      })
      .then(data => {
        this.setState({
          isLoading: false,
          movies: data.results,
          totalPages: data.total_pages,
          totalResults: data.total_results,
          pageIndex: data.page,
        });
      });
  }

  /* 页面加载时，取得数据 */
  componentDidMount() {
    this.refreshData(this.state.movieType, this.state.pageIndex);
  }

  /* 将从API处取得的数组，每四个放到一个新数组。因为我们想每行放4个 */
  getRow = () => {
    let result = [];
    let rows = [];
    if (this.state.movies) {
      let rowObj = [];
      for (let i = 0; i < this.state.movies.length; i++) {
        if (rowObj.length === 4) {
          result.push(rowObj);
          rowObj = [];
        }

        rowObj.push(<MovieItemComponent key={this.state.movies[i].id} {...this.state.movies[i]} />);
      }

      result.push(rowObj);


      for (let i = 0; i < result.length; i++) {
        rows.push(
          <Row justify="center" key={i}>
            {result[i]}
          </Row>
        );
      }

      return rows;
    }
    else {
      return null;
    }
  }

  handleChange = (e) => {
    this.setState({ movieType: e.target.value, pageIndex: 1 });
    this.refreshData(e.target.value, 1);
  }

  pageChange = (page) => {
    this.setState({ pageIndex: page });
    this.refreshData(this.state.movieType, page);
  }

  render() {
    return (
      <div className="Movies">
        <Spin spinning={this.state.isLoading} >
          <Row justify="center">
            <Col sm={22} md={21} className="search" >
              <Radio.Group onChange={this.handleChange} value={this.state.movieType}>
                <Radio style={{ margin: 0, fontSize: 13 }} value={'now_playing'}>Now Playing</Radio>
                <Radio style={{ margin: 0, fontSize: 13 }} value={'popular'}>Popular</Radio>
                <Radio style={{ margin: 0, fontSize: 13 }} value={'top_rated'}>Top Rated</Radio>
                <Radio style={{ margin: 0, fontSize: 13 }} value={'upcoming'}>Upcoming</Radio>
              </Radio.Group>
            </Col>
          </Row>
          <Row justify="center">
            <Col sm={22} md={21} className="search" >
              {
                this.state.movies ?
                  <Pagination
                    current={this.state.pageIndex}
                    total={this.state.totalResults}
                    defaultPageSize={20}
                    showSizeChanger={false}
                    onChange={this.pageChange}
                  /> : null
              }

            </Col>
          </Row>
          {
            this.getRow()
          }
        </Spin>
      </div>
    );
  }
}
